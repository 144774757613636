<template>
    <div class="wrap">
        <desLoad v-if="nomsg" :load="nomsg"></desLoad>
        <div class="content-box" v-else @click.stop="openApp()">
            <div class="box">
                <div class="top">
                    <div class="content">
                        <div class="user">
                            <img :src="rImg(m.head_100)" :onerror="txImg" class="tx" alt="" />
                            <div class="name-box">
                                <p class="name ellipsis-1">{{ m.name }}</p>
                                <p class="time">{{ m.addtime }}</p>
                            </div>
                        </div>
                        <div class="user-wrap" v-if="m.hebian_users && m.hebian_users.length">
                            <h3>本作品由以下用户附编</h3>
                            <div class="user-ul">
                                <img :src="rImg(p.head_portrait)" :onerror="txImg" class="tx" v-for="(p, pi) in m.hebian_users" alt="">
                            </div>
                        </div>
                        <div v-if="m.paystate || m.price == 0">
                            <div class="img-box" v-if="m.video">
                                <el-image class="img video-img" :src="rImg(m.cover)" fit="cover">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                            <div class="img-box" v-else-if="m.masterimg && m.masterimg.length && m.masterimg[0]">
                                <img class="img" :src="rImg(item)" v-for="item in m.masterimg" alt="">
                            </div>
                        </div>
                        <h3 class="title" v-if="m.title">{{ m.title }}</h3>
                        <div class="dy-content">
                            <div class="dy-content-des" :class="{ limit: !m.paystate && m.price != 0 }">
                                <div class="dy-des" v-html="dealTxt()"></div>
                                <div v-if="m.content_hebian && m.content_hebian.length">
                                    <div v-for="(m, mi) in m.content_hebian">
                                        <div :id="'id'+m.author.id" class="mix-user-box" v-if="m.author">
                                            <div class="line"></div>
                                            <div class="mix-user">
                                                <img class="tx" :src="rImg(m.author.head_portrait)" :onerror="txImg" alt="">
                                                <span class="name ellipsis-1">{{m.author.name}}</span>
                                                <span class="font-s" style="margin: 0;">编辑下面内容</span>
                                            </div>
                                        </div>
                                        <div class="dy-des" v-html="m.content"></div>
                                    </div>
                                </div>
                                <p v-if="!m.paystate && m.price != 0" class="art-price">支付{{m.price}}金币查看全文</p>
                            </div>
                        </div>
                        <!-- <div v-if="m.tips" v-html="m.tips"></div> -->
                        <div class="tag-box">
                            <span class="tag" :key="ti" v-for="(t, ti) in m.topic">#{{ t }}</span>
                        </div>
                        <div class="tag-box">
                            <span class="tag" :key="ti" v-for="(t, ti) in m.topic_experience">&{{ t }}</span>
                        </div>
                        <div class="dy-link" v-if="m.link">
                            <i class="el-icon-link"></i>
                            <span>点击打开链接</span>
                        </div>
                        <div class="flex-bt">
                            <div class="icon-box">
                                <div class="icon link" :class="{ active: m.islover }">
                                    <i class="new-sc"></i>
                                    <span>{{ m.lover }}</span>
                                </div>
                                <div class="icon link" :class="{ active: m.isthumbsup }">
                                    <i class="iconfont icon-dianzan"></i>
                                    <span>{{ m.num }}</span>
                                </div>
                                <div class="icon link" @click="input.show = 1">
                                    <i class="iconfont icon-xiaoxi"></i>
                                    <span>{{ m.comments || "评论" }}</span>
                                </div>
                            </div>
                            <el-button round class="ping-btn" @click="input.show = !input.show">{{ input.show ? "收起" : "评论" }}</el-button>
                            <!-- <el-button round class="ping-btn" @click="openApp()">评论</el-button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-box box" v-show="input.show">
                <el-input type="textarea" maxlength="50" :show-word-limit="true" resize="none" rows="5" placeholder="友好评论,说说你的想法" v-model="textarea"></el-input>
                <div style="text-align: right">
                    <el-button round class="ping-btn" @click="setPing">确定</el-button>
                </div>
            </div>
            <h3 class="box-title">相关评论</h3>
            <div class="box" v-if="m.comments">
                <pDyList :list="pingList"></pDyList>
                <p v-if="m.comments>10" style="color: #aaa;">更多评论请前往APP查看</p>
            </div>
        </div>
        <div class="btn-box">
            <el-button round class="app-btn" @click.stop="openApp()">打开APP</el-button>
        </div>
    </div>
</template>
<script>
    import pDyList from "@components/common/comment-dy";
    import desLoad from "@components/common/des-load";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            pDyList,
            desLoad,
        },
        data() {
            return {
                nomsg: 1,
                id: "",
                m: {},
                textarea: "",
                input: {
                    show: 0,
                },
                list: [],
                myid: 0,
                shenhe: {
                    "-1": "未通过",
                    0: "审核中",
                },
                pingList: []
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            if (!this.id) {
                this._home();
                return;
            }
            this.getData();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            dealTxt() {
                let m = this.m;
                let c = m.content;
                if (c) {
                    c = c.replace(/http(s)*:\/\//gi, "//");
                }
                // if (!m.paystate && m.price != 0) {
                //     c += ``;
                // }
                return c;
            },
            openTxt() {
                let m = this.m;
                if (!m.paystate && m.price != 0) {
                    this.openApp();
                }
            },
            // 获取详情
            async getData() {
                this.myid = this.$y_getKey("userid") || 0;
                let {
                    code,
                    msg,
                    result
                } = await this.$y_ajax("Home/Dymanic/dycommentlsts", {
                    dy_id: this.id,
                    userid: this.myid,
                });
                if (code == 200) {
                    this.m = result;
                    result.id = result.userid;
                    result.paystate = Number(result.paystate);
                    this.nomsg = 0;
                    this.setMeta(this.m.title);
                    if (result.comments) {
                        // 有评论数才请求评论列表
                        this.getPingList();
                    }
                } else {
                    this.nomsg = msg;
                }
            },
            // 点赞
            async zanFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.isthumbsup);
                let ret = await this.$y_ajax("api/Dymanic/thumbsup", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                    userid: this.myid
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    let t = this.m.num;
                    this.m.isthumbsup = s == 0 ? 1 : 0;
                    this.m.num = s == 0 ? parseInt(t) + 1 : t - 1;
                }
            },
            // 收藏
            async scFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.islover);
                let ret = await this.$y_ajax("api/Dymanic/lover", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    if (s == 0) {
                        this.m.islover = 1;
                        this.m.lover++;
                    } else {
                        this.m.islover = 0;
                        this.m.lover--;
                    }
                }
            },
            // 评论
            async setPing() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                if (!this.textarea) {
                    this.$y_msg("请输入评论");
                    return;
                }
                let {
                    code,
                    msg
                } = await this.$y_ajax("home/Dymanic/comment", {
                    dy_id: this.id,
                    userid: this.myid,
                    touserid: this.m.userid,
                    type: 0,
                    content: this.textarea,
                });
                this.$y_msg(msg);
                if (code == 200) {
                    this.textarea = "";
                }
            },
            async getPingList() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.dymanic/comments", {
                    dy_id: this.id,
                    page: 1,
                    pagesize: 10
                });
                if (code == 200) {
                    this.pingList = data;
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser() {
                this.getData();
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

    .dy-content {
        position: relative;
    }

    .box {
        .top {
            .img-box {
                display: inline-flex;
                flex-wrap: wrap;
                margin-top: 15px;
                padding-left: 10px;

                .img {
                    width: 150px;
                    height: 150px;
                    border-radius: 15px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    object-fit: cover;
                }
            }
        }
    }

    .user-wrap {
        padding: 10px;

        .user-ul {
            display: inline-flex;
            flex-wrap: wrap;
            padding-left: 10px;
            padding-top: 10px;

            .tx {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                border: 1px solid #fd5621;
                margin-bottom: 10px;
            }

        }
    }



    .mix-user-box {
        margin-top: 20px;
        position: relative;

        .mix-user {
            display: inline-flex;
            align-items: center;
            background: #fff;
            margin-left: 20px;
            position: relative;
            padding: 0 10px;
        }

        .line {
            width: 100%;
            height: 0;
            border-bottom: 1px dashed #aaa;
            position: absolute;
            left: 0;
            top: 50%;
        }

        .tx {
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
            margin-right: 7px;
        }

        .name {
            max-width: 200px;
            font-size: 0.6rem;
        }
    }



    .dy-link {
        background: #f3f3f3;
        border-radius: 3px;
        padding: 5px 7px;
        margin-top: 7px;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;

        .icon {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }

        .iconfont {
            color: #fd5621;
        }

        span {
            margin-left: 5px;
            font-size: 12px;
            color: #555;
        }
    }

    .btn-box {
        position: fixed;
        width: 100%;
        bottom: 30px;
        display: flex;
        justify-content: center;

        .app-btn {
            background: #fd5621;
            color: #fff;
        }
    }

    @media (max-width: 600px) {
        .box {
            .top {
                .img-box {
                    .img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
</style>
<style>
    .limit {
        max-height: 200px;
        overflow: hidden;
        min-height: 100px;
    }

    .art-price {
        cursor: pointer;
        text-align: center;
        height: 60%;
        padding-top: 20px;
        color: orange;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0.8));
    }

    .dy-content-des {
        line-height: 2;
    }

    .dy-content-des pre {
        white-space: normal;
    }

    .dy-content-des img {
        max-width: 100% !important;
        display: block;
        margin-bottom: 10px;
    }

    .dy-content-des .hide-img {
        display: none;
    }

    .dy-content-des span {
        display: inline;
    }
</style>